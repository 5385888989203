import classNames from 'classnames';
import { Heading } from 'components/1-atoms/Heading';
import { Picture } from 'components/1-atoms/Media';
import styles from './BannerCard.module.scss';

export interface BannerCardProps {
	bannerPicture?: UI.Image;
	bannerHeading?: string;
	video?: React.ReactNode;
	className?: string;
	headingLevel?: 'h1' | 'h2';
	headingStyle?: 'xl' | 'lg';
	manchet?: string;
	richText?: React.ReactNode;
	bannerStyle?: UI.BackgroundStyle;
	reverse?: boolean;
	imageDisplayStyle: UI.ImageDisplayStyle;
	hideImageOnSmallScreens?: boolean;
	noTopMargin: boolean;
}

export const BannerCard: React.FC<BannerCardProps> = ({
	bannerPicture,
	bannerHeading,
	video,
	className,
	manchet,
	richText,
	reverse,
	bannerStyle = 'White',
	headingLevel = 'h1',
	headingStyle = 'xl',
	imageDisplayStyle = 'cover',
	hideImageOnSmallScreens = true,
	noTopMargin = false,
}) => {
	return (
		<div
			className={classNames(
				styles.BannerCard,
				{ [styles.BannerCard___reverse]: reverse, 'u-top-margin-important--none': noTopMargin },
				bannerStyle === 'ThemeWhite' ? 'u-bg-color--white' : `u-bg-color--${bannerStyle.toLowerCase()}`,
				className,
			)}
		>
			<div className={styles.BannerCard_content}>
				{bannerHeading && (
					<Heading style={headingStyle} headingLevel={headingLevel}>
						{bannerHeading}
					</Heading>
				)}
				{manchet && <p className={styles.BannerCard_manchet}>{manchet}</p>}
				{richText}
			</div>
			{bannerPicture || video ? (
				<div
					className={classNames(
						styles.BannerCard_media,
						{ [styles.BannerCard_media___hideOnMobile]: hideImageOnSmallScreens },
						{ [styles.BannerCard_media___contained]: !(imageDisplayStyle === 'cover') },
					)}
				>
					{bannerPicture ? (
						<Picture
							className={classNames(styles.BannerCard_picture, { [styles.BannerCard_picture___cover]: imageDisplayStyle === 'cover' })}
							{...bannerPicture}
							isFullHeight
							sizes={'100vw'}
							aspectRatio={imageDisplayStyle === 'cover' ? 0.8 : undefined}
							isCover={imageDisplayStyle === 'cover'}
							padding={!imageDisplayStyle.includes('Padding') ? 'none' : imageDisplayStyle.includes('Small') ? 'small' : 'large'}
						/>
					) : (
						video
					)}
				</div>
			) : null}
		</div>
	);
};
