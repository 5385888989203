import { RichText } from 'designsystem/components/1-atoms/RichText';
import React, { useEffect } from 'react';
import { ModuleFeature } from '../DynamicModule';

export const RichTextFeature: React.FC<Content.RichText> = ({ content }) => {
	const { content: rteContent, modals } = content?.properties ?? {};

	const richTextModals = modals
		? modals?.map((modal) => {
				const { modalId, header, body } = modal?.content?.properties ?? {};
				return {
					modalId,
					header,
					body: body?.map((contentModule, index) => {
						return <ModuleFeature contentModule={contentModule} key={index} additionalProps={{ noBackground: true }} />;
					}),
				};
		  })
		: null;

	useEffect(() => {
		const anchor = window.location.hash && document.getElementById(window.location.hash.slice(1));
		if (anchor) anchor.scrollIntoView();
	}, [rteContent]);

	return <RichText content={rteContent} modals={richTextModals} />;
};
