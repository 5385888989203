import { BannerCard } from 'designsystem/components/3-organisms/BannerCard';
import { convertForRichTextFeature, RichTextFeature } from '../RichText';
import { VideoFeature } from '../Video';

export const BannerCardFeature: React.FC<Content.BannerCard> = ({ content, settings, headingLevel = 'h1', area, className }) => {
	const { picture, heading, manchet, contentEditor, video: videoArray } = content?.properties ?? {};
	const { backgroundStyle, reverse, imageDisplayStyle, hideImageOnSmallScreens, noTopMargin } = settings?.properties ?? {};
	const video = videoArray?.[0];

	const { image, altText } = picture?.[0]?.content?.properties ?? {};
	const altTextChecked = altText == '""' ? '' : altText;

	return (
		<BannerCard
			bannerPicture={
				image && {
					...image,
					altText: altTextChecked,
				}
			}
			video={
				!image && video ? (
					<VideoFeature content={video.content} documentType={video.documentType} noBackground={true} settings={video.settings} />
				) : null
			}
			hideImageOnSmallScreens={hideImageOnSmallScreens}
			bannerHeading={heading}
			className={className}
			imageDisplayStyle={imageDisplayStyle}
			headingLevel={area === 'contentSection' ? 'h2' : headingLevel}
			headingStyle={area === 'contentSection' ? 'lg' : 'xl'}
			manchet={manchet}
			richText={contentEditor && <RichTextFeature content={convertForRichTextFeature(contentEditor)} />}
			bannerStyle={backgroundStyle ? backgroundStyle : 'White'}
			reverse={reverse}
			noTopMargin={noTopMargin}
		/>
	);
};
